//
import styles from "../../styles/OverideStyles.module.css";
import { memo } from "react";
import poolImage from "../../images/pool.png";
import savePollution from "../../images/savePollution.svg";
import saveEarth from "../../images/saveEarth.svg";
// import {Image} from "react-bootstrap";
import makeFriends from "../../images/makeFriends.svg";
import { Navbar, Container, Nav, NavDropdown, Image } from "react-bootstrap";
import UnAuthNavBar from "../../components/UnAuthNavBar";
import { Grid } from "@mui/material";
const Home = (props) => {
  return (
    <>
      <Grid container style={{ height: "100%"}}>
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          textAlign={"center"}
          alignContent={"center"}
        >
          <h1 style={{ color: "white", fontSize: "50px" }}>
            Why{" "}
            <span
              style={{
                color: "white",
                fontSize: "70px",
                fontWeight: "bolder",
                fontFamily: "inherit",
              }}
            >
              Commute ?
            </span>
          </h1>
          <div className="text-center">
            <h5 style={{ color: "white" }}>
              <span>
                <img src={savePollution} width={"35px"} height={"35px"} />
              </span>{" "}
              Reduce Traffic & Pollution.
            </h5>
            <h5 style={{ color: "white" }}>
              <span>
                <img src={saveEarth} width={"35px"} height={"35px"} />
              </span>{" "}
              Save Money & Fuel.
            </h5>
            <h5 style={{ color: "white" }}>
              <span>
                <img src={makeFriends} width={"35px"} height={"35px"} />
              </span>{" "}
              Make Friends On The Way.
            </h5>
          </div>
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          style={{ textAlign: "center" }}
        >
          <Image src={poolImage} style={{width:"80%", height:"auto", objectFit:"contain"}} />
        </Grid>
      </Grid>
    </>
  );
};

export default memo(Home);
