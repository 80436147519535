import { configureStore } from "@reduxjs/toolkit";
import authReducerUser from "./features/authUser.store";
import notifications from "./features/notifications.store";

export const store = configureStore({
    reducer:{
        authUser:authReducerUser,
        notifications:notifications
    }
});
