import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../styles/OverideStyles.module.css";
import toast, { Toaster } from "react-hot-toast";
import { Form as BsForm, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setUserDetails, setUserToken } from "../../store/features/authUser.store";
import { socket } from "../../socket";
import { configuration } from "../../apis/config";
import { Typography } from "@mui/material";

const CryptoJS = require("crypto-js");
const config = require("../../config");

function LoginForm() {
  toast.remove();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function submitHandler(e) {
    e.preventDefault();
    const phone = e.target.phone.value;
    const password = e.target.password.value;
    const pwd = String(CryptoJS.AES.encrypt(password, config.encKey));
    try {
      const resp = await axios({
        method: configuration.urls.loginMethod,
        url: configuration.urls.login,
        data: {
          phone: phone,
          password: pwd,
        },
      });
      if (resp.status == 200 && resp.data.data.jwt) {
        localStorage.setItem("token", resp.data.data.jwt);
        localStorage.setItem("userDetails", JSON.stringify(resp.data.data.userDetails));
        dispatch(setUserToken({ token: resp.data.data.jwt }));
        dispatch(setUserDetails({ userDetails: resp.data.data.userDetails }));
        socket.emit("saveUser");
        socket.connect();
        navigate("/app/pools")
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  }

  return (
    <div
      className={`${styles.loginBg}`}
      // used https://bgjar.com/sprinkle
      style={{
        width: "100%",
        height: "100%",
        alignContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#171a1d",
          borderRadius: "10px",
          width: "auto",
          maxWidth: "350px",
          minWidth: "250px",
          margin: "auto",
        }}
        className="shadow"
      >
        <Toaster />
        <BsForm className={`p-4`} onSubmit={submitHandler}>
          <div className="mb-3">
            <Typography className="text-white" variant="h4" fontWeight={"bolder"}>Login</Typography>
          </div>

          <BsForm.Group controlId="phone" className="mb-3">
            <BsForm.Label className="col-form-label text-white">Phone Number</BsForm.Label>
            <BsForm.Control
              className={styles.formfield}
              size="sm"
              name="phone"
              id="phone"
              type="number"
              placeholder="Enter your phone number"
            />
          </BsForm.Group>

          <BsForm.Group controlId="password" className="mb-3">
            <BsForm.Label className="col-form-label text-white">Password</BsForm.Label>
            <BsForm.Control
              className={styles.formfield}
              size="sm"
              name="password"
              id="password"
              type="password"
              placeholder="Enter your password"
            />
          </BsForm.Group>
          <Button
            size="sm"
            type="submit"
            className={`btn btn-warning ${styles.loginbtn} mb-3`}
          >
            Login
          </Button>
          <div className="text-center mb-3">
            <Typography variant="body2" className="text-white">
              Not a member ?{" "}
              <Link
                to="/signup"
                className={`link-warning ${styles.signupfooterlink}`}
              >
                Sign Up
              </Link>
            </Typography>
          </div>
          <div className="row">
            <div className="col text-center">
              <Typography variant="body2">
                <Link
                  to="/forgot"
                  className={`link-warning ${styles.signupfooterlink}`}
                >
                  Forgot Password
                </Link>
              </Typography>
            </div>
            <div className="col text-center">
              <Typography variant="body2">
                <Link
                  to="/resend"
                  className={`link-warning ${styles.signupfooterlink}`}
                >
                  Verifiy
                </Link>
              </Typography>
            </div>
          </div>
        </BsForm>
      </div>
    </div>

  );
}
export default LoginForm;
