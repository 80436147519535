import * as config from "../config";
const baseUrl = config.baseUrl;
export const configuration = {
  base: baseUrl,
  urls: {
    dashboard: `${baseUrl}`,
    dashboardMethod: ``,

    login: `${baseUrl}user/login`,
    loginMethod: `POST`,

    signup: `${baseUrl}user/register`,
    signupMethod: `POST`,

    resendOtp: `${baseUrl}user/resend/otp/`,
    resendOtpMethod: `POST`,

    verifyOtp: `${baseUrl}user/verify/otp/`,
    verifyOtpMethod: `POST`,

    verifyHash: `${baseUrl}user/verify/hash/`,
    verifyHashMethod: `POST`,

    forgotPassword: `${baseUrl}user/forgot/password`,
    forgotPasswordMethod: `POST`,

    updatePassword: `${baseUrl}user/forgot/password/update`,
    updatePasswordMethod: `POST`,

    resendVerification: `${baseUrl}user/resendVerification`,
    resendVerificationMethod: `POST`,

    changePassword: `${baseUrl}user/update/password`,
    changePasswordMethod: `POST`,

    account: `${baseUrl}user/info`,
    accountMethod: `POST`,

    changeName: `${baseUrl}user/update/name`,
    changeNameMethod: `POST`,

    loginActivity: `${baseUrl}user/activity`,
    loginActivityMethod: `POST`,

    commanderRegister: `${baseUrl}commander/add/`,
    commanderRegisterMethod: `POST`,

    vehiclesRegister: `${baseUrl}vehicles/add/`,
    vehiclesRegisterMethod: `POST`,

    vehiclesGetAll: `${baseUrl}vehicles/get/all`,
    vehiclesGetAllMethod: `POST`,

    createPool: `${baseUrl}pools/create`,
    createPoolMethod: `POST`,

    getPools: `${baseUrl}pools/get`,
    getPoolsMethod: `POST`,

    getPoolsByQuery: `${baseUrl}pools/getpools`,
    getPoolsByQueryMethod: `POST`,

    getPoolsHistory: `${baseUrl}pools/myPools`,
    getPoolsHistoryMethod: `POST`,

    joinPool: `${baseUrl}requests/join`,
    joinPoolMethod: `POST`,

    decidePool: `${baseUrl}requests/decide`,
    decidePoolMethod: `POST`,

    getNotifications: `${baseUrl}notifications/get`,
    getNotificationsMethod: `POST`,

    payment: `${baseUrl}payments/pay`,
    paymentMethod: `POST`,

    addPaymentMethod: `${baseUrl}payments/add`,
    removePaymentMethod: `${baseUrl}payments/remove`,

    addBalance: `${baseUrl}wallet/addBalance`,
    addBalanceMethod: `POST`,

    getWallet: `${baseUrl}wallet/get`,
    getWalletMethod: `POST`,

    walletTransfer: `${baseUrl}wallet/transfer`,
    walletTransferMethod: `POST`,

    addBalanceRedirect: `${baseUrl}wallet/addBalance/redirect`,
    addBalanceMethod: `POST`,

    paymentHistory: `${baseUrl}wallet/history`,
    paymentHistoryMethod: `POST`,
  },
};

export const configurationAdmin = {
  base: baseUrl,
  urls: {
    dashboard: `${baseUrl}`,
    dashboardMethod: ``,

    totalAccount: `${baseUrl}admin/totalInfo`,
    totalAccountMethod: `POST`,

    login: `${baseUrl}admin/login`,
    loginMethod: `POST`,

    signup: `${baseUrl}admin/register`,
    signupMethod: `POST`,

    users: `${baseUrl}admin/users`,
    usersMethod: `POST`,

    admins: `${baseUrl}admin/admins`,
    adminsMethod: `POST`,

    requests: `${baseUrl}admin/requests`,
    requestsMethod: `POST`,

    approveOrReject: `${baseUrl}admin/approveOrReject`,
    approveOrRejectMethod: `POST`,
  },
};
