import { createSlice } from "@reduxjs/toolkit";

const notificationReducer = createSlice({
    name: "notifications",
    initialState: {
        notifications: []
    },
    reducers: {
        setNotifications(state, action) {
            state.notifications = action.payload.notifications;
        },
        pushNotifications(state, action) {
            state.notifications.push(action.payload.notification);
        }
    },

});

export const { setNotifications, pushNotifications } = notificationReducer.actions;

export default notificationReducer.reducer;
