// Uncomment for prod

module.exports = {
    encKey: "mahteerp1998",
    jwtSecret: "1f93fbdd62a04fc752a49aaf9b946c0900f64e83f39a612995532f7990f73952",
    jwtSecretAdmin: "d268c50ca7556fab7b28778b0eb4f7fec63272acc8735cb9bfa635344f0cf4ed",
    baseUrl:"https://43.204.35.255:3002/",
    rKey: "rzp_live_QQGzTI1G8K1wku" ,
    socketUrl:"https://43.204.35.255:3002",
    maps: "AIzaSyCe_csGuwZcY7eUjCwwyoHzaVsduXt6pF0"
}


// Uncomment for local

// module.exports = {
//     encKey: "mahteerp1998",
//     jwtSecret: "1f93fbdd62a04fc752a49aaf9b946c0900f64e83f39a612995532f7990f73952",
//     jwtSecretAdmin: "d268c50ca7556fab7b28778b0eb4f7fec63272acc8735cb9bfa635344f0cf4ed",
//     baseUrl: process.env.NODE_ENV == "prod" ? "http://43.204.35.255:3002/" : "http://localhost:3002/",
//     rKey: process.env.NODE_ENV == "prod" ? "rzp_live_QQGzTI1G8K1wku" : "rzp_test_EVd4ty4c6hUu4b",
//     socketUrl: process.env.NODE_ENV == "prod" ? "http://43.204.35.255:3002/" : "http://localhost:3002",
//     maps: "AIzaSyCe_csGuwZcY7eUjCwwyoHzaVsduXt6pF0"
// }