import { Navbar, Container, Nav, NavDropdown, Image } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";

export default function UnAuthNavBar() {
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh", backgroundColor: "#212529", }}>
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{ backgroundColor: "#212529" }}
      >
        <Container>
          <Navbar.Brand>
            <NavLink to={"/"}>
              <Image
                src={require(".././images/icon.png")}
                className="d-inline-block align-top"
                width={"45px"}
                height={"45px"}
                rounded
              />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link style={{ color: "white" }}>
                <NavLink
                  className={`nav-link`}
                  to={"/login"}
                  style={({ isActive }) => {
                    return { color: !isActive ? "white" : "#ffc107" };
                  }}
                >
                  Login
                </NavLink>
              </Nav.Link>

              <Nav.Link style={{ color: "white" }}>
                <NavLink
                  className={`nav-link`}
                  to={"/signup"}
                  style={({ isActive }) => {
                    return { color: !isActive ? "white" : "#ffc107" };
                  }}
                >
                  Signup
                </NavLink>
              </Nav.Link>

              {/* <Nav.Link style={{ color: "white" }}>
                <NavLink
                  className={`nav-link`}
                  to={"/about"}
                  style={({ isActive }) => {
                    return { color: !isActive ? "white" : "#ffc107" };
                  }}
                >
                  About Us
                </NavLink>
              </Nav.Link> */}

              <Nav.Link style={{ color: "white" }}>
                <NavLink
                  className={`nav-link`}
                  to={"/contact"}
                  style={({ isActive }) => {
                    return { color: !isActive ? "white" : "#ffc107" };
                  }}
                >
                  Contact Us
                </NavLink>
              </Nav.Link>

              <Nav.Link style={{ color: "white" }}>
                <NavLink
                  className={`nav-link`}
                  to={"/privacy"}
                  style={({ isActive }) => {
                    return { color: !isActive ? "white" : "#ffc107" };
                  }}
                >
                  Privacy Policy
                </NavLink>
              </Nav.Link>

              <Nav.Link style={{ color: "white" }}>
                <NavLink
                  className={`nav-link`}
                  to={"/terms"}
                  style={({ isActive }) => {
                    return { color: !isActive ? "white" : "#ffc107" };
                  }}
                >
                  Terms & Conditions
                </NavLink>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </div>
  );
}
