import { Container } from "react-bootstrap"
import UnAuthNavBar from "../../components/UnAuthNavBar"
export default function Contact(){
    return <>
    <div style={{backgroundColor:"#212529", height:"auto", width:"100%"}}>
        <Container>
            <h2 style={{textAlign:"center", color:"white"}}>Contact Us</h2>
            <p style={{textAlign:"center",color:"white"}}>You may contact us using the below mentioned email address</p>
            <p style={{textAlign:"center",color:"white"}}>Email: <span style={{color:"#ffc107"}}>admin@commute.network</span></p>
            {/* <p style={{textAlign:"center",color:"white"}}>Phone: <span style={{color:"#ffc107"}}>+91 8008161484</span></p>
            <p style={{textAlign:"center",color:"white"}}>Address: <span style={{color:"#ffc107"}}>2nd street, CSK Green Villas, Shadnagar, Telangana, Rangareddy district, 509216</span></p> */}
        </Container>
    </div>
    </>
}