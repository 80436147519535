// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { jwtSecret, jwtSecretAdmin } from "../../config";
// import * as jose from "jose";

// export const joseAsyncUser = createAsyncThunk(
//     'auth/joseAsyncUser',
//     async () => {
//         const tkn = localStorage.getItem("token");
//         if (tkn !== null) {
//             const encodeSecret = new TextEncoder().encode(jwtSecret);
//             try {
//                 const resp = await jose.jwtVerify(tkn, encodeSecret);
//                 console.log("Inside jose: ", { token: tkn, error: false });
//                 return { token: tkn, error: false };
//             }
//             catch (err) {
//                 if (err.name == "JWTExpired") {
//                     localStorage.removeItem("token");
//                     return { token: false, error: true };
//                 }
//                 localStorage.removeItem("token");
//                 return { token: false, error: true };
//             }
//         }
//         else if (tkn == null) {
//             return { token: false, error: true };
//         }
//     }
// );

// const authReducerUser = createSlice({
//     name: "authUser",
//     initialState: {
//         token: localStorage.getItem("token"),
//         error: false,
//     },
//     reducers: {
//     },
//     extraReducers: (builder) => {
//         builder.addCase(joseAsyncUser.fulfilled, (state, action) => {
//             state.token = action.payload.token;
//             state.error = action.payload.error;
//         })
//     }

// });

// export const checkUser = (state) => {
//     console.log("Check User State: ", state)
//     return state.authUser;
// }

// export default authReducerUser.reducer;









import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { jwtSecret, jwtSecretAdmin } from "../../config";
import * as jose from "jose";

const authReducerUser = createSlice({
    name: "authUser",
    initialState: {
        token: localStorage.getItem("token"),
        userDetails: JSON.parse(localStorage.getItem("userDetails")),
        error: false,
    },
    reducers: {
        setUserToken(state, action) {
            state.token = action.payload.token;
        },
        setUserDetails(state, action) {
            state.userDetails = action.payload.userDetails;
        }
    },

});

export const { setUserToken, setUserDetails } = authReducerUser.actions;

export default authReducerUser.reducer;